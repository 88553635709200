import VueRecaptcha from 'vue-recaptcha';
export default {
	name: "Quiz",
	created () {
		this.$nextTick(function () {
			this.GetQuiz()
		});
	},
	mounted () {
		this.$nextTick(function () {
		})
	},
	data () {
		return {
			QuizData: [],
			answerData: [],
			selectAnswerData: [],
			checkCaptch: false,
			Disabled: true,
			code: '',
			name: '',
			email: '',
			phone: '',
			localStorageL: localStorage.getItem('lang'),
			grade: 0,
			DONEdisabled: true
		};
	},
	computed: {

	},
	watch: {

	},
	methods: {
		// 获取数据
		GetQuiz () {
			$.ajax({
				type: "GET",
				dataType: 'json',
				url: this.$$.mibb + "GetQuiz",
				data: { token: "webkey" },
				success: (data) => {
					if (data.status == 'SUCCESS') {
						let arr = []
						$.each(data.text, (i, c) => {
							arr = []
							if (c.option1) {
								arr[0] = c.option1
								this.answerData[i] = c.option1
							}
							if (c.option2) {
								arr[1] = c.option2
							}
							if (c.option3) {
								arr[2] = c.option3
							}
							if (c.option4) {
								arr[3] = c.option4
							}
							this.QuizData[i] = {
								select: arr.sort(() => Math.random() > .5 ? -1 : 1),
								question: c.question
							}
						})
						this.$forceUpdate()
					}
				},
				error: function (XMLHttpRequest) { }
			});
		},
		SendQuizEmail: function () {
			if (this.name && this.email && this.phone && this.code) {
				this.Disabled = true;
				$.ajax({
					type: "GET",
					url: this.$$.mibbmail + "SendQuizEmail",
					data: { name: this.name, email: this.email, phone: this.phone, code: this.code, scored: this.grade },
					dataType: "json",
					success: (data) => {
						if (data.status === "SUCCESS") {
							// 模态框出现
							$('#QuizModal').modal('hide')
						}
					},
					error: function (error) {
						console.log(error);
					}
				});
			} else {
				this.Disabled = false
			}
		},
		selectAnswer: function (index, answer) {
			this.selectAnswerData[index] = answer
			if (this.selectAnswerData.length == this.QuizData.length) {
				this.DONEdisabled = false
			}
		},
		DONE: function () {
			let num = 0
			$.each(this.answerData, (i, c) => {
				if (this.selectAnswerData[i] == c) {
					num++
				}
			})
			this.grade = 100 / this.QuizData.length * num
			$('#QuizModal').modal('show')
		},
		onVerify: function (response) {
			this.code = response;
			if (response == "") {
				this.checkCaptch = false;
			} else {
				this.checkCaptch = true;
				if (this.checkCaptch) {
					this.Disabled = false
				} else {
					this.Disabled = true
				}
			}
		},
		onExpired: function () {
			this.checkCaptch = false;
			this.Disabled = true;
		},
	},
	components: { VueRecaptcha }
};